import React, { useEffect } from "react";
import { Provider, rootStore } from "./stores/main";
import { ConfigProvider, Layout } from "antd";
import { BrowserRouter as Router, Route, useParams } from "react-router-dom";
import LogRocket from "logrocket";
import { TopBar } from "./components/playground-top-bar/TopBar";
import { useHistory } from "react-router-dom";
import { MainLayout } from "./components/MainLayout";
import { ToastContainer } from "react-toastify";
import { fetchUserAttributes, signInWithRedirect } from "aws-amplify/auth";
import BlingsTheme from "./config/antdTheme";
import withSetupTOTPMFA from "./components/customAuth/withSetupTOTPMFA";
import { ConfigureApp, withDefaultSignIn } from "@blings/shared-auth-component";
import awsmobile from "./aws-exports";
import { Amplify } from "aws-amplify";
import { Hub } from "aws-amplify/utils";
import { getCurrentUser, signIn, signOut } from "aws-amplify/auth";
import { getENV, info_data } from "./stores/consts";
import { Authenticator, ThemeProvider } from "@aws-amplify/ui-react";
import "./App.scss";
import "./layout.scss";
import "./toastify.css";
import { parsePathName, tabsPathResolver } from "./utils/navigation";
ConfigureApp(awsmobile, Amplify, signInWithRedirect);

const App: React.FC = () => {
  const history = useHistory();
  const location = parsePathName(window.location.pathname);

  const { projectId, scene } = useParams<{
    projectId: string | undefined;
    scene: string | undefined;
  }>();

  // todo: combine both useEffect at once, with proper deps
  useEffect(() => {
    (async () => {
      const promises = [
        rootStore.accountStore.loadAccountData(),
        rootStore.platformStore.loadProjects(),
      ];
      await Promise.all(promises);
      if (rootStore.platformStore.allProjects.length && projectId) {
        await rootStore.platformStore.selectProject(
          projectId,
          decodeURIComponent(location.scene)
        );
        if (
          rootStore.platformStore.projectWorkspaceVersion &&
          rootStore.platformStore.projectWorkspaceVersion.videoParts?.length &&
          !location.scene
        ) {
          let route = "/" + tabsPathResolver(location);
          if (location.tab === "layers")
            route += `/${rootStore.platformStore.projectWorkspaceVersion.videoParts[0].name}`;
          history.push(route);
        }
      }
    })();
    rootStore.notificationStore.init();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    function listener(e: BeforeUnloadEvent) {
      const { hasUnsavedChanges } = rootStore.platformStore;
      if (hasUnsavedChanges) {
        e.preventDefault();
        e.returnValue = "Latest changes have not been saved.";
        return "Latest changes have not been saved.";
      }
    }
    window.addEventListener("beforeunload", listener);
    return () => {
      window.removeEventListener("beforeunload", listener);
    };
  }, []);

  useEffect(() => {
    if (!rootStore.platformStore.allProjects.length) {
      console.log("No projects found");
      return;
    }
    (async () => {
      if (projectId !== rootStore.platformStore.selectedProjectId) {
        await rootStore.platformStore.selectProject(projectId);
      }
      if (location.scene)
        rootStore.platformStore.changeVideoPart(
          decodeURIComponent(location.scene)
        );
      else if (
        rootStore.platformStore.projectWorkspaceVersion &&
        rootStore.platformStore.projectWorkspaceVersion.videoParts?.length
      ) {
        let route = "/" + tabsPathResolver(location);
        if (location.tab === "layers")
          route += `/${rootStore.platformStore.projectWorkspaceVersion.videoParts[0].name}`;
        history.push(route);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectId, location.scene]);

  useEffect(() => {
    if (process.env.NODE_ENV === "production") {
      LogRocket.init("xodu2t/blings_playground");
      (async () => {
        const data = await fetchUserAttributes();
        // const data = await Auth.currentUserInfo();
        LogRocket.identify("USER_IN_APP_ID", {
          name: data.sub as string,
          email: data.email as string,
          id: data.sub as string,
        });
      })();
    }
  }, []);

  return (
    <ConfigProvider theme={BlingsTheme} wave={{ disabled: true }}>
      <Layout>
        <TopBar />
        <MainLayout />
      </Layout>
      <ToastContainer
        position="bottom-left"
        autoClose={10000}
        hideProgressBar={false}
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </ConfigProvider>
  );
};
const AppWithRouter = () => {
  useEffect(() => {
    (async () => {})();
  }, []);
  return (
    <Provider value={rootStore}>
      <Router>
        <Route
          path={"/:projectId?/:scene?"}
          component={withDefaultSignIn(withSetupTOTPMFA(App), {
            config: {
              amplify: {
                Hub: Hub,
                getCurrentUser: getCurrentUser,
                signIn: signIn,
                signOut: signOut,
              },
              ui: {
                Authenticator: Authenticator,
                ThemeProvider: ThemeProvider,
              },
              environment: getENV(),
            },
            infoData: {
              contact_email: info_data.contactMail,
              support: info_data.support,
            },
          })}
        />
      </Router>
    </Provider>
  );
};

export default AppWithRouter;
